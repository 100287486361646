<script>
import Layout from "../../layouts/main";
import PageHeader from "@/components/page-header";

/**
 * Starter page component
 */
export default {
    page() {
        return {
            title: this.title,
            meta: [{ name: "description" }],
        };
    },
    components: { Layout, PageHeader },
    data() {
        return {
            title: "Starter page",
            items: [
                {
                    text: "Utility",
                    href: "/",
                },
                {
                    text: "Starter page",
                    active: true,
                },
            ],

            ismobile: false,
            activeapp: [],
            showmodel: "1",
            userinfo: [],
            safeinfo: [],
            loading: true,
            form: {},
            initializationdata: {},
            activeNames: []
        };
    },
    mounted() {
        this.title = this.$t("menuitems.setting.initialization");
        this.items = [
            {
                text: this.$t("menuitems.setting.text"),
                href: "/",
            },
            {
                text: this.$t("menuitems.setting.initialization"),
                active: true,
            },
        ];

        this.userinfo = JSON.parse(localStorage.getItem("user"));
        let flag = navigator.userAgent.match(
            /(phone|pod|iPhone|iPod|ios|Android|Mobile|BlackBerry|IEMobile|MQQBrowser|JUC|Fennec|wOSBrowser|BrowserNG|WebOS|Symbian|Windows Phone)/i
        );
        if (flag) {
            this.ismobile = true;
        }
        this.loading = false
        this.get_initialization()
    },
    methods: {
        get_initialization() {
            console.log("get_initialization")
            var that = this
            that.loading = true
            that.$axios
                .post(
                    that.apiuri,
                    {
                        action: "get_initialization",
                    },
                    {
                        headers: { Openid: that.userinfo.openid },
                    }
                )
                .then(function (response) {
                    that.loading = false;
                    that.initializationdata = response.data.data;
                })
                .catch(function (error) {
                    console.log(error);
                });
        },
        save_initialization() {
            console.log("save_initialization")
            var that = this
            that.loading = true
            that.$axios
                .post(
                    that.apiuri,
                    {
                        action: "save_initialization",
                        data: that.initializationdata
                    },
                    {
                        headers: { Openid: that.userinfo.openid },
                    }
                )
                .then(function (response) {
                    that.loading = false;
                    that.$message({
                        message: response.data.msg,
                        type: "success",
                    });
                    that.get_initialization()
                })
                .catch(function (error) {
                    console.log(error);
                });
        },
    },
};
</script>

<template>
    <Layout>
        <PageHeader :title="title" :items="items" />
        <div class="row">
            <div class="col-12">
                <div class="card">
                    <div class="card-body" v-loading="loading">
                        <el-table :data="initializationdata.hdlist" style="width: 100%;margin-bottom: 20px;" row-key="ED_id"
                            border default-expand-all :tree-props="{ children: 'children', hasChildren: 'hasChildren' }">
                            <el-table-column prop="ED_id" label="设备ID" sortable width="130">
                            </el-table-column>
                            <el-table-column label="设备名称" sortable width="180">
                                <template slot-scope="scope">
                                    <el-input v-if="scope.row.name || scope.row.no==1" v-model="scope.row.name" size="small" style="width: 100%;"></el-input>
                                </template>
                            </el-table-column>
                            <el-table-column label="销售商品" sortable width="180">
                                <template slot-scope="scope">
                                    <el-select v-model="scope.row.gid" filterable allow-create default-first-option
                                        placeholder="请选择">
                                        <el-option value="0" label="不设置商品"></el-option>
                                        <el-option v-for="item in initializationdata.goodslist" :key="item.id"
                                            :label="item.title" :value="item.id">
                                        </el-option>
                                    </el-select>
                                </template>
                            </el-table-column>
                            <el-table-column label="销售价格" sortable width="120">
                                <template slot-scope="scope">
                                    <el-input-number v-model="scope.row.price" :min="0" :max="99999999" :step="0.01"
                                        controls-position="right" size="small" style="width: 100%;"></el-input-number>
                                </template>
                            </el-table-column>
                            <el-table-column label="销售量(ML)" sortable width="130">
                                <template slot-scope="scope">
                                    <el-input-number v-model="scope.row.stock" :min="0" :max="99999999" :step="1"
                                        controls-position="right" size="small" style="width: 100%;"></el-input-number>
                                </template>
                            </el-table-column>
                            <el-table-column label="库存(ML)" sortable width="130">
                                <template slot-scope="scope">
                                    <el-input-number v-model="scope.row.stock_save" :min="0" :max="99999999" :step="1"
                                        controls-position="right" size="small" style="width: 100%;" placeholder="0"></el-input-number>
                                </template>
                            </el-table-column>
                        </el-table>
                        <p style="text-align: right;">
                            <el-button type="primary" @click="save_initialization">保存</el-button>
                        </p>
                    </div>
                </div>
            </div>
        </div>
    </Layout>
</template>